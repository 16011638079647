export default () => {
  console.log('running proto 5')

  let bodypix,
    video,
    canvas,
    drawCanvas,
    bufferCanvas,
    maskCanvas,
    ctx,
    dctx,
    bctx,
    mctx,
    gui,
    stats,
    stopped = false

  const SCALE = 0.5
  const W = 1280 * SCALE
  const H = 720 * SCALE
  const CX = W / 2
  const CY = H / 2

  const options = {
    multiplier: 0.75, // 1.0, 0.75, or 0.50, 0.25
    outputStride: 16, // 8, 16, or 32, default is 16
    segmentationThreshold: 0.65 // 0 - 1, defaults to 0.5
  }

  const config = {
    zoom: 0.5,
    rotation: 0,
    smear: 0.25,
    opacity: 0.5,
    figureBlur: 0.33,
    figureContrast: 1,
    backgroundBlur: 0.22
  }

  function stop() {
    stopped = true
  }

  async function setup() {
    document.body.className = 'init'

    // create canvii
    canvas = document.createElement('canvas')
    drawCanvas = document.createElement('canvas')
    bufferCanvas = document.createElement('canvas')
    maskCanvas = document.createElement('canvas')

    ctx = canvas.getContext('2d')
    dctx = drawCanvas.getContext('2d')
    bctx = bufferCanvas.getContext('2d')
    mctx = maskCanvas.getContext('2d')

    // get the video
    video = await getVideo()

    // size the canvas and video
    resizeStage()

    // start render loop
    loop()

    // show canvas
    document.querySelector('.content').appendChild(canvas)

    document.body.className = 'loading'

    // load bodyPix with video
    bodypix = await ml5.bodyPix(video, options)

    document.body.className = 'loaded'

    // run the segmentation on the video, handle the results in a callback
    bodypix.segment(onSegment)

    initGUI()
  }

  function initGUI() {
    gui = new dat.GUI()
    gui.add(options, 'segmentationThreshold', 0.01, 0.99)
    gui.add(config, 'opacity', 0, 1)
    gui.add(config, 'smear', 0, 1)
    gui.add(config, 'zoom', -1, 1)
    gui.add(config, 'figureBlur', 0, 1)
    // gui.add(config, "figureContrast", 0, 500);
    gui.add(config, 'backgroundBlur', 0, 1)
    //gui.add(config, "rotation", -1.11, 1.11);
    // gui.close()

    stats = new Stats()
    document.body.appendChild(stats.domElement)
    stats.domElement.className = 'stats-box'
  }

  function onSegment(err, segmentation) {
    if (err) {
      console.log(err)
      return
    }

    if (stopped) {
      console.log('stopped segmentation')
      return
    }

    // write image data to our mask canvas
    mctx.putImageData(segmentation.raw.personMask, 0, 0)

    bodypix.segment(onSegment, options)
  }

  function loop(t) {
    if (stopped) {
      console.log('stopped loop')
      return
    }

    if (stats) stats.begin()

    bctx.clearRect(0, 0, W, H)
    bctx.filter = 'blur(' + W * 0.05 * config.figureBlur + 'px)'
    bctx.drawImage(maskCanvas, 0, 0)
    bctx.filter = 'none'

    // use mask as an actual mask
    dctx.clearRect(0, 0, W, H)
    dctx.globalAlpha = 1
    dctx.globalCompositeOperation = 'source-over'
    // dctx.filter = 'contrast('+config.figureContrast+')';
    dctx.filter = 'none'
    dctx.drawImage(bufferCanvas, 0, 0)

    dctx.filter = 'none' //'contrast('+config.figureContrast+')';
    // Use existing content as a mask
    dctx.globalCompositeOperation = 'source-in'

    // Dynamic fill color
    const rgb = [120, (150 + Math.sin(t / 1111) * 100) >> 0, 220]
    dctx.fillStyle = `rgb(${rgb})`
    dctx.globalAlpha = 1
    dctx.fillRect(0, 0, W, H)

    // back to normal compositing
    dctx.globalCompositeOperation = 'source-over'

    // draw video feed as low opacity overlay
    // In background, it will pile up. under blob, it will be obscured.
    dctx.globalAlpha = 0.1
    dctx.filter = 'blur(' + W * 0.05 * config.backgroundBlur + 'px)'
    dctx.drawImage(video, 0, 0, W, H)
    dctx.filter = 'none'

    // buffer
    bctx.clearRect(0, 0, W, H)
    bctx.globalAlpha = 1
    bctx.save()
    bctx.translate(CX, CY + 0.5)
    bctx.scale(1 + config.zoom / 50, 1 + config.zoom / 50)
    bctx.rotate(config.rotation / 10)
    bctx.translate(-CX, -CY)

    // capture last frame to buffer
    bctx.drawImage(canvas, 0, 0)
    bctx.restore()

    ctx.globalAlpha = config.smear
    ctx.drawImage(bufferCanvas, 0, 0, W, H)

    ctx.globalAlpha = config.opacity
    ctx.drawImage(drawCanvas, 0, 0, W, H)

    if (stats) stats.end()
    window.requestAnimationFrame(loop)
  }

  // webcam video feed
  async function getVideo() {
    const videoElement = document.createElement('video')
    videoElement.width = W
    videoElement.height = H

    const capture = await navigator.mediaDevices.getUserMedia({ video: { width: W, height: H } })

    videoElement.srcObject = capture
    videoElement.play()

    return videoElement
  }

  function resizeStage() {
    ;[canvas, drawCanvas, bufferCanvas, maskCanvas, video].forEach((c) => {
      c.width = W
      c.height = H
    })
  }

  setup()

  return stop
}
