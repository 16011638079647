import {
  Application,
  BaseTexture,
  Graphics,
  Texture,
  Sprite,
  filters,
  resources,
  BLEND_MODES
} from 'pixi.js'

import MediaStreamResource from '../util/MediaStreamResource'
import * as StackBlur from 'stackblur-canvas'

// prototype - v08 - More video mask demos, testing RenderTexture, and cleanup
export default () => {
  let app, gui, bodypix, maskCanvas, mctx, maskTexture, webcamVideo

  const bodypixOptions = {
    outputStride: 16, // 8, 16, or 32, default is 16
    segmentationThreshold: 0.5 // 0 - 1, defaults to 0.5
  }

  // stage size
  const W = 1280
  const H = 720

  // capture scale
  const SCALE = 0.25
  const CW = W * SCALE
  const CH = H * SCALE

  // setup
  const setup = async () => {
    app = new Application({
      antialias: true,
      width: W,
      height: H,
      transparent: true
    })

    app.stage.interactive = true

    // Get webcam
    const capture = await navigator.mediaDevices.getUserMedia({ video: { width: CW, height: CH } })

    // MediaStream wraps Video and allows us to pass in the srcObject (instead of relying on 'src')
    const captureRes = new MediaStreamResource(capture)
    webcamVideo = captureRes.source
    webcamVideo.width = CW
    webcamVideo.height = CH

    // We have webcam.
    document.body.className = 'loading'

    // Setup sprites
    const captureSprite = new Sprite(new Texture(new BaseTexture(captureRes, { mipmap: false })))

    // video loops
    const videoRes = new resources.VideoResource('/video/tree_shadows.mp4')
    videoRes.source.loop = true
    videoRes.source.playbackRate = 0.5

    const videoRes2 = new resources.VideoResource('/video/liquid_pour_alpha.mp4')
    videoRes2.source.loop = true
    videoRes2.source.playbackRate = 0.25

    const videoSprite = new Sprite(new Texture(new BaseTexture(videoRes, { mipmap: false })))
    const videoSprite2 = new Sprite(new Texture(new BaseTexture(videoRes2, { mipmap: false })))

    // load bodyPix with video
    bodypix = await ml5.bodyPix(webcamVideo, bodypixOptions)

    document.body.className = 'loaded'

    // create canvas and texture for mask data
    maskCanvas = document.createElement('canvas')
    maskCanvas.width = CW
    maskCanvas.height = CH
    mctx = maskCanvas.getContext('2d')

    maskTexture = new Texture.from(maskCanvas)

    const maskSprite = new Sprite(maskTexture)

    // set sizes and add to stage
    ;[captureSprite, videoSprite2, videoSprite, maskSprite].forEach((s) => {
      s.width = W
      s.height = H
      s.x = 0
      s.y = 0
      app.stage.addChild(s)
    })

    // alpha, mask, and filters
    captureSprite.alpha = 0.7
    captureSprite.filters = [new filters.BlurFilter(16)]

    videoSprite.alpha = 0.9
    videoSprite.mask = maskSprite

    videoSprite2.alpha = 0.7
    videoSprite2.blendMode = BLEND_MODES.ADD

    // show app
    document.querySelector('.content').appendChild(app.view)

    // show textures on screen for debugging
    // document.querySelector('.debug').appendChild(captureRes.source)
    // document.querySelector('.debug').appendChild(maskCanvas)

    // run the segmentation on the video, handle the results in a callback
    bodypix.segment(onSegment)
  }

  function onSegment(err, segmentation) {
    if (err) {
      console.log(err)
      return
    }

    if (maskTexture && mctx) {
      // write image data to our mask canvas
      mctx.globalCompositeOperation = 'source-over'

      // Blur the person mask using quasimondo's stackblur on the raw imageData
      StackBlur.imageDataRGBA(segmentation.raw.personMask, 0, 0, CW, CH, CW / 32)

      // put image data (black and transparent) onto our mask canvas
      mctx.putImageData(segmentation.raw.personMask, 0, 0)

      // fill mask area with white instead of black for pixi
      // (Using our raw mask as a canvas mask as we make our pixi mask)
      mctx.globalCompositeOperation = 'source-in'
      mctx.fillStyle = '#fff'
      mctx.fillRect(0, 0, CW, CH)

      // Tell pixi mask texture to update
      maskTexture.update()
    }

    bodypix.segment(onSegment, bodypixOptions)
  }

  function initGUI() {
    // gui = new dat.GUI()
    // gui.add(bodypixOptions, 'segmentationThreshold', 0.01, 0.99)
    // gui.add(config, 'opacity', 0, 1)
    // gui.add(config, 'smear', 0, 1)
    // gui.add(config, 'zoom', -1, 1)
    // gui.add(config, 'figureBlur', 0, 1)
    // gui.add(config, 'backgroundBlur', 0, 1)
  }

  // const loop = () => {}

  setup()
  initGUI()
}
