import { Application, BaseTexture, Texture, Sprite, filters } from 'pixi.js'
import { ColorReplaceFilter } from 'pixi-filters'
import MediaStreamResource from '../util/MediaStreamResource'

// prototype - v06 - Now using pixi
export default () => {
  let app, gui, stats, video, bodypix, maskCanvas, mctx, maskTexture, maskSprite, maskBitmap

  const options = {
    multiplier: 0.75, // 1.0, 0.75, or 0.50, 0.25
    outputStride: 16, // 8, 16, or 32, default is 16
    segmentationThreshold: 0.45 // 0 - 1, defaults to 0.5
  }

  const SCALE = 0.5
  const W = 1280 * SCALE
  const H = 720 * SCALE
  // const CX = W / 2
  // const CY = H / 2

  // setup
  const setup = async () => {
    app = new Application({ antialias: true, width: W, height: H })
    document.querySelector('.content').appendChild(app.view)

    app.stage.interactive = true
    app.stage.width = W
    app.stage.height = H

    // Get webcam
    const capture = await navigator.mediaDevices.getUserMedia({ video: { width: W, height: H } })

    // MediaStream wraps Video and allows us to pass in the srcObject (instead of relying on 'src')
    const res = new MediaStreamResource(capture)

    video = res.source

    // size the video element
    video.width = W
    video.height = H

    const mediaSprite = new Sprite(new Texture(new BaseTexture(res, { mipmap: false })))

    app.stage.addChild(mediaSprite)

    document.body.className = 'loading'

    // load bodyPix with video
    bodypix = await ml5.bodyPix(video, options)

    document.body.className = 'loaded'

    // create canvas and texture for mask data
    maskCanvas = document.createElement('canvas')
    maskCanvas.width = W
    maskCanvas.height = H
    mctx = maskCanvas.getContext('2d')

    maskTexture = new Texture.from(maskCanvas)

    const maskSprite = new Sprite(maskTexture)
    maskSprite.x = 0
    maskSprite.y = 0
    maskSprite.alpha = 0.85
    app.stage.addChild(maskSprite)

    // Filters
    const backgroundBlur = new filters.BlurFilter()
    backgroundBlur.blur = 5

    const bodyBlur = new filters.BlurFilter()
    bodyBlur.blur = 10

    mediaSprite.filters = [backgroundBlur]
    maskSprite.filters = [new ColorReplaceFilter(0x000000, 0xfcfe54), bodyBlur]

    // run the segmentation on the video, handle the results in a callback
    bodypix.segment(onSegment)
  }

  async function onSegment(err, segmentation) {
    if (err) {
      console.log(err)
      return
    }

    if (stats) stats.begin()

    if (maskTexture && mctx) {
      // write image data to our mask canvas
      mctx.putImageData(segmentation.raw.personMask, 0, 0)

      // Tell pixi mask texture to update
      maskTexture.update()
    }

    bodypix.segment(onSegment, options)

    if (stats) stats.end()
  }

  function initGUI() {
    gui = new dat.GUI()
    gui.add(options, 'segmentationThreshold', 0.01, 0.99)
    // gui.add(config, 'opacity', 0, 1)
    // gui.add(config, 'smear', 0, 1)
    // gui.add(config, 'zoom', -1, 1)
    // gui.add(config, 'figureBlur', 0, 1)
    // gui.add(config, 'backgroundBlur', 0, 1)

    // stats = new Stats()
    // document.body.appendChild(stats.domElement)
    // stats.domElement.className = 'stats-box'
  }

  // const loop = () => {}

  setup()
  initGUI()
}
