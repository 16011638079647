// import process from 'process'
import dat from 'dat.gui'
import Stats from 'stats.js'

import {
  Application,
  BaseTexture,
  Graphics,
  Texture,
  RenderTexture,
  Sprite,
  filters,
  SpriteMaskFilter,
  BLEND_MODES,
  utils
} from 'pixi.js'

import { ColorReplaceFilter } from 'pixi-filters'
import * as StackBlur from 'stackblur-canvas'
import MediaStreamResource from './util/MediaStreamResource'
import { interpColor } from './util/interpolation'

const DEFAULT_CONFIG = {
  globalAlpha: 0.8,
  webcamAlpha: 0.75,
  backgroundAlpha: 0.85,
  bodyAlpha: 0.5,
  fillAlpha: 0.9,
  guideAlpha: 1,
  guideScale: 1,
  zoom: 1.1,
  cFade: 0.975,
  cAlpha: 0.5,
  initialBlur: 0.5
}

export default (settings) => async () => {
  const DEV_MODE = true // process.env.NODE_ENV === 'development'

  if (DEV_MODE) {
    console.log('Loading Scene', { settings })
  }

  let pos = 0

  // local vars
  let app,
    gui,
    stats,
    bodypix,
    maskCanvas,
    mctx,
    bctx,
    tctx,
    maskTexture,
    maskOverlayTexture,
    webcamVideo,
    mainVideo,
    fillSprite,
    guideSprite,
    curtainSprite;

  // settings from JSON
  const {
    guideVideo,
    fillVideo,
    fillColors,
    bodyColors,
    backgroundColors,
    backgroundVideo,
    curtainFadeDuration,
    curtainColor,
    config: customConfig
  } = settings

  // bodypix configuration
  const bodypixOptions = {
    outputStride: 16, // 8, 16, or 32, default is 16
    segmentationThreshold: 0.4 // 0 - 1, defaults to 0.5
  }

  // runtime configuration
  const config = {
    ...DEFAULT_CONFIG,
    ...customConfig
  }

  // stage size
  const SCALE = 1
  const W = 1280 * SCALE
  const H = 720 * SCALE

  // capture scale
  const CSCALE = 0.25
  const CW = W * CSCALE
  const CH = H * CSCALE

  const GUIDE_SIZE = 512 * SCALE

  // setup
  const setup = async () => {
    app = new Application({
      antialias: true,
      width: W,
      height: H,
      transparent: false,
      backgroundColor: 0xcccccc
    })

    app.stage.interactive = true

    // Get webcam
    const capture = await navigator.mediaDevices.getUserMedia({ video: { width: CW, height: CH } })

    // MediaStream wraps Video and allows us to pass in the srcObject (instead of relying on 'src')
    const captureRes = new MediaStreamResource(capture)
    webcamVideo = captureRes.source
    webcamVideo.width = CW
    webcamVideo.height = CH

    // We have webcam
    document.body.className = 'loading'

    // load bodyPix with video
    bodypix = await ml5.bodyPix(webcamVideo, bodypixOptions)

    // Webcam sprite with some blur
    const captureSprite = new Sprite(new Texture(new BaseTexture(captureRes, { mipmap: false })))
    captureSprite.filters = [new filters.BlurFilter(16)]
    // captureSprite.blendMode = BLEND_MODES[config.backgroundBlendMode]

    // Flip the capture sprite (for mirror)
    captureSprite.anchor.x = 1
    captureSprite.scale.x = -1

    // Canvas and Texture for Mask data
    maskCanvas = document.createElement('canvas')
    mctx = maskCanvas.getContext('2d')
    maskCanvas.width = CW
    maskCanvas.height = CH
    bctx = document.createElement('canvas').getContext('2d')
    bctx.canvas.width = CW
    bctx.canvas.height = CH
    tctx = document.createElement('canvas').getContext('2d')
    tctx.canvas.width = CW
    tctx.canvas.height = CH

    const fillColorFilter = new ColorReplaceFilter(0xffffff, 0xff0000)
    const bodyColorFilter = new ColorReplaceFilter(0xffffff, 0xff0000)

    maskTexture = new Texture.from(bctx.canvas)
    maskOverlayTexture = new Texture.from(mctx.canvas)

    // Mask Sprite (flipped for mirror)
    const maskSprite = new Sprite(maskTexture)
    maskSprite.anchor.x = 1
    maskSprite.scale.x = -1

    const bodySprite = new Sprite(maskOverlayTexture)
    bodySprite.anchor.x = 1
    bodySprite.scale.x = -1
    bodySprite.filters = [bodyColorFilter]

    let backgroundSprite, backgroundVideoEl, fillVideoEl, guideVideoEl

    // Background (video or colors)
    if (backgroundVideo) {
      // Background Video Sprite
      backgroundSprite = new Sprite(Texture.from(backgroundVideo))
      backgroundVideoEl = backgroundSprite.texture.baseTexture.resource.source
      backgroundVideoEl.loop = false
      backgroundVideoEl.playsInline = true
      backgroundVideoEl.autoplay = true

      // Use background blend mode defined in settings
      backgroundSprite.blendMode = BLEND_MODES[config.backgroundBlendMode]
    } else {
      // using background colors
      backgroundSprite = new Graphics()
      backgroundSprite.beginFill(0xffffff)
      backgroundSprite.drawRect(0, 0, 16, 16)
      backgroundSprite.endFill()
      backgroundSprite.filters = [fillColorFilter]
    }

    // Fill (video or colors)
    if (fillVideo) {
      fillSprite = new Sprite(Texture.from(fillVideo))
      fillVideoEl = fillSprite.texture.baseTexture.resource.source
      fillVideoEl.loop = false
      fillVideoEl.playsInline = true
      fillVideoEl.autoplay = true

      // // Trying spriteMask
      maskSprite.renderable = false
      const maskFilter = new SpriteMaskFilter(maskSprite)
      // maskFilter.blendMode = BLEND_MODES[config.backgroundBlendMode]
      captureSprite.blendMode = BLEND_MODES[config.backgroundBlendMode]
      fillSprite.filters = [maskFilter]
      // fillSprite.mask = maskSprite
    } else {
      // using fill colors - use the mask sprite as the blob for color fill (no need for mask)
      fillSprite = maskSprite

      // Use color replace filter for dynamic fill color
      fillSprite.filters = [fillColorFilter]
    }

    mainVideo = backgroundVideoEl || fillVideoEl

    // Guide Video
    if (guideVideo) {
      guideSprite = new Sprite(Texture.from(guideVideo))
      guideVideoEl = guideSprite.texture.baseTexture.resource.source
      guideVideoEl.loop = false
      guideVideoEl.playsInline = true
      guideVideoEl.autoplay = true
      guideSprite.width = GUIDE_SIZE
      guideSprite.height = GUIDE_SIZE
      guideSprite.x = W - GUIDE_SIZE
      guideSprite.y = 0
      guideSprite.blendMode = BLEND_MODES[config.guideBlendMode]
    }

    // create two render textures... these dynamic textures will be used to draw the scene into itself
    let renderTexture = RenderTexture.create(app.screen.width, app.screen.height)
    let renderTexture2 = RenderTexture.create(app.screen.width, app.screen.height)
    const currentTexture = renderTexture

    // create a new sprite that uses the render texture we created above
    const outputSprite = new Sprite(currentTexture)

    // Setup full-screen overlay sprites
    const sprites = [
      /*outputSprite,*/ captureSprite,
      backgroundSprite,
      fillSprite,
      maskSprite,
      bodySprite
    ]

    sprites.forEach((s) => {
      if (s) {
        s.width = W
        s.height = H
      }
    })

    // update alphas
    const A = config.globalAlpha
    guideSprite.alpha = config.guideAlpha * A
    fillSprite.alpha = config.fillAlpha * A
    captureSprite.alpha = config.webcamAlpha * A
    backgroundSprite.alpha = config.backgroundAlpha * A
    bodySprite.alpha = config.bodyAlpha * A

    app.stage.addChild(outputSprite)
    app.stage.addChild(captureSprite)
    app.stage.addChild(backgroundSprite)
    app.stage.addChild(fillSprite)

    // // webcam in background unless we need to overlay masked fillVideo which can't have blend mode
    if (fillVideo) {
      app.stage.addChild(captureSprite)
    }
    app.stage.addChild(maskSprite)
    app.stage.addChild(bodySprite)
    app.stage.addChild(guideSprite)

    // show app
    document.querySelector('.content').appendChild(app.view)
    document.querySelector('.content').addEventListener('dblclick', toggleFullScreen)

    // run the segmentation on the video, handle the results in a callback
    bodypix.segment(onSegment)

    const startTime = +new Date()
    let previousFrameTime = startTime;
    let timeRemaining = 1000000000;
    // let nextTick = 1

    curtainSprite = new Sprite(Texture.WHITE);
    curtainSprite.width = W;
    curtainSprite.height = H;
    curtainSprite.tint = utils.rgb2hex(curtainColor.map((c) => c / 255));
    curtainSprite.alpha = 0;

    app.stage.addChild(curtainSprite);

    // Loop
    app.ticker.add(() => {
      if (stats) stats.begin()
      try {
        const { currentTime, duration } = mainVideo
        pos = (currentTime || 0) / (duration || 1)
        timeRemaining = (duration - currentTime);
      } catch (e) {
        console.log(e)
      }

      let colors = []

      if (!backgroundVideo && backgroundColors) {
        colors = backgroundColors
      } else {
        colors = fillColors
      }

      if (fillColorFilter && colors && colors.length) {
        fillColorFilter.newColor = utils.rgb2hex(interpColor(pos, colors).map((c) => c / 255))
      }

      if (bodyColors && bodyColors.length) {
        bodyColorFilter.newColor = utils.rgb2hex(interpColor(pos, bodyColors).map((c) => c / 255))
      }
      
      const nowTime = +new Date()
      const t = (nowTime - startTime) / 1000
      
      let deltaTime = Math.max(0, t - previousFrameTime);
      previousFrameTime = t;

      // Fade to the curtainFillColor at the end of the experience.      
      if(timeRemaining < curtainFadeDuration) {
        let increment = deltaTime / curtainFadeDuration;
        curtainSprite.alpha = Math.min(1, curtainSprite.alpha + increment);
      }
      
      // const tps = 1
      // let tick = false
      // if (t * tps > nextTick * tps) {
      //   nextTick = Math.ceil(t * tps) / tps
      //   tick = true
      // }

      // // add trails to body canvas
      // sticking with 2D since we've already got a canvas texture
      // TODO: consider using Pixi RenderTexture here as well

      const z = 1 + config.zoom * 0.01 // * (Math.sin(t * config.zoomSpeed) + 1)

      // save last frame to temporary canvas
      tctx.clearRect(0, 0, CW, CH)
      tctx.drawImage(bctx.canvas, 0, 0)
      bctx.clearRect(0, 0, CW, CH)

      // draw last frame back to clean buffer, but zoomed slightly
      bctx.save()
      bctx.translate(CW / 2, CH / 2)
      bctx.scale(z, z)
      // bctx.rotate(config.rotation / 10)
      bctx.translate(-CW / 2, -CH / 2)

      // draw last frame at less than 100% for trails that fade off
      bctx.globalAlpha = config.cFade
      bctx.drawImage(tctx.canvas, 0, 0)
      bctx.restore()

      // Draw latest blurred segmentation blob onto our canvas
      // (this doesn't change much, but that's why we're here)
      bctx.globalAlpha = config.cAlpha
      bctx.drawImage(mctx.canvas, 0, 0)

      // Tell pixi mask texture to update
      maskTexture.update()

      // update alphas (only if using gui)
      if (gui) {
        const A = config.globalAlpha
        guideSprite.alpha = config.guideAlpha * A
        fillSprite.alpha = config.fillAlpha * A
        captureSprite.alpha = config.webcamAlpha * A
        backgroundSprite.alpha = config.backgroundAlpha * A
        bodySprite.alpha = config.bodyAlpha * A

        const gs = GUIDE_SIZE * config.guideScale
        guideSprite.width = gs
        guideSprite.height = gs
        guideSprite.x = W - gs
      }

      // swap the main buffers
      const temp = renderTexture
      renderTexture = renderTexture2
      renderTexture2 = temp
      outputSprite.texture = renderTexture

      // render the stage to the texture
      // the 'true' clears the texture before the content is rendered
      app.renderer.render(app.stage, renderTexture2, false)

      if (stats) stats.end()
    })

    document.body.className = 'loaded'
  }

  const onSegment = (err, segmentation) => {
    if (err) {
      console.log(err)
      return
    }

    if (maskTexture && mctx) {
      // write image data to our mask canvas
      mctx.globalCompositeOperation = 'source-over'

      // Blur the person mask using quasimondo's stackblur on the raw imageData
      const blur = (32 * config.initialBlur) >> 0
      StackBlur.imageDataRGBA(segmentation.raw.personMask, 0, 0, CW, CH, blur)

      // put image data (black and transparent) onto our mask canvas
      mctx.putImageData(segmentation.raw.personMask, 0, 0)

      // fill mask area with white instead of black for pixi
      // (Using our raw mask as a canvas mask as we make our pixi mask)
      mctx.globalCompositeOperation = 'source-in'
      mctx.fillStyle = '#fff'
      mctx.fillRect(0, 0, CW, CH)

      maskOverlayTexture.update()
    }

    bodypix.segment(onSegment, bodypixOptions)
  }

  const initGUI = () => {
    gui = new dat.GUI({ width: 320, autoPlace: true })
    gui.domElement.id = 'gui'

    stats = new Stats()
    stats.domElement.style.position = 'static'
    ;[].forEach.call(stats.domElement.children, (child) => (child.style.display = ''))

    // gui.add(bodypixOptions, 'segmentationThreshold', 0.01, 0.99)
    let folder = gui.addFolder('Layers')
    folder.add(config, 'globalAlpha', 0.01, 1)
    folder.add(config, 'webcamAlpha', 0.01, 1)
    folder.add(config, 'backgroundAlpha', 0.01, 1)
    folder.add(config, 'fillAlpha', 0.01, 1)
    folder.add(config, 'bodyAlpha', 0.01, 1)
    folder.add(config, 'guideAlpha', 0.01, 1)
    folder.open()

    folder = gui.addFolder('FX')
    folder.add(config, 'cFade', 0.933, 0.999)
    folder.add(config, 'cAlpha', 0, 1)
    folder.add(config, 'zoom', -2, 2)
    // gui.add(config, 'zoomSpeed', 0, 1)
    folder.add(config, 'initialBlur', 0.125, 2)
    // gui.add(config, 'backgroundBlur', 0, 1)
    // folder.open()

    folder = gui.addFolder('Masks')
    const canvasLi = document.createElement('li')
    canvasLi.appendChild(mctx.canvas)
    canvasLi.appendChild(bctx.canvas)
    canvasLi.className = 'gui-canvas'
    folder.__ul.appendChild(canvasLi)
    // folder.open()

    folder = gui.addFolder('Stats')
    const perfLi = document.createElement('li')
    perfLi.appendChild(stats.domElement)
    perfLi.className = 'gui-stats'
    folder.__ul.appendChild(perfLi)
    // folder.open()

    gui.close()
  }

  function toggleFullScreen() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  await setup()

  if (DEV_MODE) initGUI()
}
