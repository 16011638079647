import { Application, BaseTexture, Graphics, Texture, Sprite, filters, resources } from 'pixi.js'
// import { ColorReplaceFilter } from 'pixi-filters'
import MediaStreamResource from '../util/MediaStreamResource'
import * as StackBlur from 'stackblur-canvas'

// prototype - v07 - demonstration of pixi masking and video texture
export default () => {
  let app, gui, stats, video, bodypix, maskCanvas, mctx, maskTexture

  const options = {
    multiplier: 0.75, // 1.0, 0.75, or 0.50, 0.25
    outputStride: 16, // 8, 16, or 32, default is 16
    segmentationThreshold: 0.5 // 0 - 1, defaults to 0.5
  }

  const SCALE = 0.25
  const W = 1280 * SCALE
  const H = 720 * SCALE

  // setup
  const setup = async () => {
    app = new Application({
      antialias: true,
      width: W / SCALE,
      height: H / SCALE,
      transparent: true
    })
    document.querySelector('.content').appendChild(app.view)

    app.stage.interactive = true

    // Get webcam
    const capture = await navigator.mediaDevices.getUserMedia({ video: { width: W, height: H } })

    // MediaStream wraps Video and allows us to pass in the srcObject (instead of relying on 'src')
    const res = new MediaStreamResource(capture)

    video = res.source

    // size the video element
    video.width = W
    video.height = H

    const backgroundVideoRes = new resources.VideoResource('/video/tree_shadows.mp4')
    backgroundVideoRes.source.loop = true

    const backgroundSprite = new Sprite(
      new Texture(new BaseTexture(backgroundVideoRes, { mipmap: false }))
    )

    const mediaSprite = new Sprite(new Texture(new BaseTexture(res, { mipmap: false })))
    mediaSprite.alpha = 0.5

    document.body.className = 'loading'

    // load bodyPix with video
    bodypix = await ml5.bodyPix(video, options)

    document.body.className = 'loaded'

    // create canvas and texture for mask data
    maskCanvas = document.createElement('canvas')
    maskCanvas.width = W
    maskCanvas.height = H
    mctx = maskCanvas.getContext('2d')

    maskTexture = new Texture.from(maskCanvas)

    const maskSprite = new Sprite(maskTexture)

    // yellow fill
    const graphics = new Graphics()
    graphics.beginFill(0xfcfe54)
    graphics.drawRect(0, 0, 16, 16)
    graphics.endFill()
    graphics.alpha = 0.9

    // set sizes and add to stage
    ;[mediaSprite, backgroundSprite, maskSprite, graphics].forEach((s) => {
      s.width = W / SCALE
      s.height = H / SCALE
      s.x = 0
      s.y = 0
      app.stage.addChild(s)
    })

    // Filters
    const backgroundBlur = new filters.BlurFilter()
    backgroundBlur.blur = 10

    mediaSprite.filters = [backgroundBlur]

    graphics.mask = maskSprite

    // show textures on screen for debugging
    document.querySelector('.debug').appendChild(video)
    document.querySelector('.debug').appendChild(maskCanvas)

    // run the segmentation on the video, handle the results in a callback
    bodypix.segment(onSegment)
  }

  function onSegment(err, segmentation) {
    if (err) {
      console.log(err)
      return
    }

    if (stats) stats.begin()

    if (maskTexture && mctx) {
      // const data = segmentation.raw.personMask.data
      //
      // for (let i = 0; i < data.length; i += 4) {
      //   data[i] = data[i + 1] = data[i + 2] = 255
      // }

      // write image data to our mask canvas
      mctx.globalCompositeOperation = 'source-over'

      // Blur the person mask using quasimondo's stackblur on the raw imageData
      StackBlur.imageDataRGBA(segmentation.raw.personMask, 0, 0, W, H, W / 32)

      // put image data (black and transparent) onto our mask canvas
      mctx.putImageData(segmentation.raw.personMask, 0, 0)

      // fill mask area with white instead of black for pixi
      // (Using our raw mask as a canvas mask as we make our pixi mask)
      mctx.globalCompositeOperation = 'source-in'
      mctx.fillStyle = '#fff'
      mctx.fillRect(0, 0, W, H)

      // Tell pixi mask texture to update
      maskTexture.update()
    }

    bodypix.segment(onSegment, options)

    if (stats) stats.end()
  }

  function initGUI() {
    gui = new dat.GUI()
    gui.add(options, 'segmentationThreshold', 0.01, 0.99)
    // gui.add(config, 'opacity', 0, 1)
    // gui.add(config, 'smear', 0, 1)
    // gui.add(config, 'zoom', -1, 1)
    // gui.add(config, 'figureBlur', 0, 1)
    // gui.add(config, 'backgroundBlur', 0, 1)

    // stats = new Stats()
    // document.body.appendChild(stats.domElement)
    // stats.domElement.className = 'stats-box'
  }

  // const loop = () => {}

  setup()
  initGUI()
}
