import './styles.scss'

import settings from './settings.json'

import scene from './scene'

// prototypes
import v05 from './proto/bodypix05_canvas'
import v06 from './proto/bodypix06_pixi'
import v07 from './proto/bodypix07_masks'
import v08 from './proto/bodypix08_video'
import v09 from './proto/bodypix09_render'

const prototypes = { v05, v06, v07, v08, v09 }

if (typeof window !== 'undefined') {
  const paths = {
    ...prototypes
  }
  window.onload = () => {
    // Add scenes to list of paths
    settings.scenes.forEach((options) => {
      paths[options.slug] = scene(options)
    })

    // Get sketch name from URL
    const path = window.location.pathname.split('/')[1]

    if (typeof paths[path] === 'function') {
      // Run the sketch
      paths[path]()
    } else {
      // TODO: Show index?
      console.log('Nothing here at ', path)
    }
  }
}
